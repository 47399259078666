import {
    Box,
    Column,
    Hidden,
} from 'native-base';
import DashboardBackButton from './DashboardBackButton';
import DashboardProps from './DashboardProps';
import DesktopOnlyWrapper from './DesktopOnlyWrapper';
import DownloadAppStoreQRCodes from '@ui/components/appStore/DownloadAppStoreQRCodes';
import Header from '@ui/layout/header/Header';
import MainContent from '@ui/layout/MainContent';
import React from 'react';
import ScrollViewWrapper from '@ui/layout/ScrollViewWrapper';
import {TopBarWrapper} from './TopBarWrapper';

const DashboardLayout = (props: DashboardProps) => {
    const {header = {}, _main, _container, useMainContentContainer = true, useScrollView = true, extra = {}, ...restProps} = props;
    const {selectedTab = 'none', showBackButton = false, showBorder = true, backTitle, _right, _center, _leftProps, _centerProps} = header;

    const isOrganizer = header.view === 'cms';

    return (
        <DesktopOnlyWrapper isOrganizer={isOrganizer}>
            <Column
                flex={1}
                bg='blacks.100'
                {..._main}
            >
                <Header
                    title={props.title}
                    showMenuItems={props.showMenuItems}
                    selectedTab={selectedTab}
                    showBorder={showBackButton === false && showBorder}
                    view={header.view}
                    _right={!showBackButton ? _right : undefined}
                />

                {!isOrganizer &&
            <Hidden till='lg'>
                <Box position="absolute" top={190 + (showBackButton ? 50 : 0) + (extra.qrTop ?? 0)} left={10} zIndex={500}>
                    <DownloadAppStoreQRCodes />
                </Box>
            </Hidden>
                }

                {showBackButton &&
                <TopBarWrapper
                    _row={{bg: 'white', borderBottomColor: 'secondary.0', borderBottomWidth: showBorder ? '1px' : 0}}
                    right={_right}
                    _center={{
                        px: '4',
                        ..._centerProps,
                    }}
                    _left={_leftProps}

                >
                    <DashboardBackButton title={backTitle} _center={_center} onBack={header.onBack}/>
                </TopBarWrapper>}

                <TopBarWrapper _row={{flex: 1}} fullWidth={!useMainContentContainer} _center={_container}>
                    {useScrollView ? (
                        <ScrollViewWrapper>
                            <MainContent {...restProps} />
                        </ScrollViewWrapper>
                    ) : (
                        <MainContent {...restProps} />
                    )}
                </TopBarWrapper>
            </Column>
        </DesktopOnlyWrapper>
    );
};

export default DashboardLayout;
