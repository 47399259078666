import {
    Box,
    Hidden,
    Row,
    Text,
    useBreakpointValue,
    useDisclose,
    useTheme,
} from 'native-base';
import React, {useEffect, useState} from 'react';
import AuthHeader from './AuthHeader';
import {HeaderItemsLarge} from './HeaderItemsLarge';
import {HeaderItemsSmall} from './HeaderItemsSmall';
import {HeaderProps} from './HeaderProps';
import IcWAMP from '@ui/svg/IcWAMP';
import LoginModal from '@ui/features/auth/login/LoginModal';
import NavigationButton from '@ui/components/buttons/NavigationButton';
import NavigationLink from '@ui/components/buttons/NavigationLink';
import {TopBarWrapper} from '@ui/layout/TopBarWrapper';
import {useTranslation} from 'react-i18next';
import {useUser} from '@ui/provider/user/UserProvider';

const HeaderItems = (props: HeaderProps) => {
    const {showMenuItems = true} = props;
    const {t} = useTranslation();
    const {isLoggedIn, isAdmin} = useUser();
    const [view, setView] = useState<'user' | 'cms'>(props.view ?? 'user');
    const isSmallScreen = useBreakpointValue({base: true, lg: false});
    const [path, setPath] = useState<'/' | '/organizer'>('/organizer');
    const {isOpen, onOpen, onClose} = useDisclose();

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(false);
    }, []);

    useEffect(() => {
        setPath(view !== 'user' ? '/' : '/organizer');
    }, [view]);

    if(!showMenuItems) {
        return null;
    }

    const isCms = view === 'cms';

    const onToggleView = () => {
        if(isLoggedIn){
            const newView = isCms ? 'user' : 'cms';
            setView(newView);
        } else {
            onOpen();
        }
    };

    const onFinishLogin = () => {
        setView('cms');
        onClose();
    };

    return (
        <Row
            space='8'
            px={{
                base: 4,
                lg: 0,
            }}
            alignItems="center"
            minH='72px'
            w='full'
        >
            {isCms || isLoading ?
                <></> :
                isSmallScreen
                    ? <HeaderItemsSmall {...props} />
                    : <HeaderItemsLarge {...props} />
            }

            <Box flex={1}/>

            {isAdmin && <Hidden till='lg'>
                <NavigationButton
                    variant='ghostOutline'
                    onPress={onToggleView}
                    path={path}
                    right={isLoggedIn ? 0 : 10}
                >
                    {t(`header.view.${view === 'user' ? 'cms' : 'user'}`)}
                </NavigationButton>
            </Hidden>}

            <LoginModal isOpen={isOpen} onClose={onClose} onFinish={onFinishLogin} />
        </Row>
    );

};

const Header = (props: HeaderProps) => {
    const {title, showBorder = true, _right} = props;
    const theme = useTheme();

    const iconWidth = useBreakpointValue({
        base: 0,
        lg: 42,
    });

    const rightFlex = useBreakpointValue({
        base: 1,
        lg: 2,
    });

    return (
        <Box
            bg='white'
            borderBottomWidth={showBorder ? '1px' : 0}
            borderBottomColor='secondary.100'
        >
            <TopBarWrapper
                left={
                    <NavigationLink
                        px='8'
                        path='/'
                    >
                        <IcWAMP fill={theme.colors.black} width={iconWidth} height={22}/>
                    </NavigationLink>
                }
                right={<AuthHeader/>}
                _row={{alignItems: 'center'}}
                _right={{w: 'full', flex: rightFlex}}
            >
                <HeaderItems {...props} />
            </TopBarWrapper>

            {title &&
            <Row
                bg='white'
                h='72px'
                alignItems='center'
            >
                <Box flex={1}/>

                <Row flex={8} alignItems="center" justifyContent="space-between" w='full'>
                    <Text variant='h3'>{title}</Text>

                    {_right}
                </Row>

                <Box flex={1}/>
            </Row>
            }
        </Box>
    );
};

export default React.memo(Header);
